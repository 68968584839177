const copyright = [
    {
        url: "mengyabaobei",
        text: "粤ICP备19029485号"
    },
    {
        url: "kunkunma",
        text: "粤ICP备19029485号"
    },
    {
        url: "qiaomeng",
        text: "粤ICP备19029485号"
    },
    {
        url: "xiaomanxiong",
        text: "粤ICP备19029485号"
    },
    {
        url: "duoduofen",
        text: "粤ICP备19029485号"
    },
    {
        url: "wuzhehui",
        text: "粤ICP备19029485号"
    },
    {
        url: "xiongbiwang",
        text: "粤ICP备19029485号"
    },
    {
        url: "txxiaolu",
        text: "粤ICP备19029485号-1"
    },
    {
        url: "txtaogou",
        text: " 粤ICP备19029485号-2"
    },
    {
        url: "txchengzi",
        text: "粤ICP备19029485号-2"
    },
    {
        url: "txjuzi",
        text: "粤ICP备19029485号-2"
    },
];
export default copyright;