<style lang="less">
@import url("./assets/less/base.less");

header {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 10;
  &.white {
    background: #fff;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
  }
  .app-content {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon {
      width: 168px;
      height: 32px;
      background: url("./assets/img/首页_slices/logo_bear.png");
      font-size: 0;
    }
    .nav {
      display: flex;
      align-items: center;
      font-size: 16px;
      nav {
        position: relative;
        font-weight: 400;
        color: #333333;
        white-space: nowrap;
        line-height: 1.4;
        cursor: pointer;
        &:hover {
          font-weight: bold;
          &::after {
            position: absolute;
            content: "";
            width: 13px;
            height: 6px;
            background: url("./assets/img/首页_slices/ic_bear_noise@2x.png");
            left: 50%;
            transform: translateX(-50%);
            bottom: -6px;
            background-size: cover;
          }
        }
        & + nav {
          margin-left: 60px;
        }
        &.active {
          font-weight: bold;
          &::after {
            position: absolute;
            content: "";
            width: 13px;
            height: 6px;
            background: url("./assets/img/首页_slices/ic_bear_noise@2x.png");
            left: 50%;
            transform: translateX(-50%);
            bottom: -6px;
            background-size: cover;
          }
        }
      }
      .download {
        padding: 0 16px;
        height: 32px;
        border-radius: 20px;
        border: 1px solid #333333;
        text-align: center;
        line-height: 32px;
        margin-left: 60px;
        &::after {
          content: "";
          display: inline-block;
          width: 16px;
          height: 16px;
          background: url("./assets/img/首页_slices/ic_download@2x.png");
          background-size: cover;
          margin-left: 4px;
          position: relative;
          top: 1px;
        }
      }
    }
  }
}
.app-view {
  min-height: calc(100vh - 100px);
}
footer {
  background: #333333;
  .app-content {
    display: flex;
    height: 100px;
    align-items: center;
    justify-content: space-between;
    .icon {
      width: 130px;
      height: 26px;
      background: url("./assets/img/首页_slices/logo_bear02.png");
      background-size: 100% 100%;
      font-size: 0;
    }
    .-right {
      .nav {
        display: flex;
        nav {
          font-size: 12px;
          color: #ffffff;
          cursor: pointer;
          & + nav {
            margin-left: 60px;
          }
        }
      }
      .copyright {
        height: 17px;
        font-size: 12px;
        color: #ffffff;
        line-height: 17px;
        margin-top: 12px;
        opacity: 0.3;
        text-align: right;
        display: block;
      }
    }
  }
}
.flot-tool {
  position: fixed;
  top: 294px;
  right: 0;
  background: #fff;
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.03);
  z-index: 2;
  border-radius: 8px;
  .icon {
    position: relative;
    width: 60px;
    height: 60px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0;
    &:hover {
      text-align: center;
      background: #ffca00;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 20px;
      &::after {
        content: none;
      }
    }
    &::after {
      content: "";
      width: 30px;
      height: 30px;
      background-size: 100% 100%;
    }
    &.icon-1 {
      border-radius: 8px 8px 0 0;
      &:hover {
        &::before {
          position: absolute;
          left: -178px;
          top: 0;
          content: "";
          width: 167px;
          height: 182px;
          z-index: 2;
          background-size: cover;
          background: url("./assets/img/首页_slices/imng_qr_gzh.png");
        }
      }
      &::after {
        background: url("./assets/img/首页_slices/ic_qr.png");
      }
    }
    &.icon-2 {
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      &:hover {
        &::before {
          position: absolute;
          left: -178px;
          top: 0;
          content: "";
          width: 167px;
          height: 62px;
          z-index: 2;
          background-size: cover;
          background: url("./assets/img/首页_slices/img_hezuo_tel.png");
        }
      }
      &::after {
        background: url("./assets/img/首页_slices/ic_hezuo.png");
      }
    }
    &.icon-3 {
      border-radius: 0 0 8px 8px;
      &::after {
        background: url("./assets/img/首页_slices/ic_download(1).png");
      }
    }
  }
}
</style>
<template>
  <!-- 头部 -->
  <header :class="{ white: scrollTop }">
    <div class="app-content">
      <div class="icon" @click="pageTo(0)">小熊有好货ICON</div>
      <div class="nav">
        <nav
          v-for="(item, index) in navs"
          :key="item.name"
          :class="{ active: index === navIndex }"
          @click="pageTo(index)"
        >
          {{ item.name }}
        </nav>
        <div class="download" @click="pageTo">免费下载</div>
      </div>
    </div>
  </header>
  <!-- 更改的 -->
  <div class="app-view">
    <router-view />
  </div>
  <!-- 页脚 -->
  <footer>
    <div class="app-content">
      <div class="icon" @click="pageTo(0)">小熊有好货ICON</div>
      <div class="-right">
        <div class="nav">
          <nav
            v-for="(item, index) in navs"
            :key="item.name"
            @click="pageTo(index)"
          >
            {{ item.name }}
          </nav>
        </div>
        <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
          class="copyright"
        >
          copyright@2018-2022 深圳市俏梦科技有限公司 版权所有 | {{copyrightText}}
        </a>
      </div>
    </div>
  </footer>
  <!-- 浮标 -->
  <div class="flot-tool">
    <div class="icon icon-1">微信<br />公众号</div>
    <div class="icon icon-2">商务<br />合作</div>
    <div class="icon icon-3" @click="VscrollTop">置顶</div>
  </div>
</template>

<script>
import copyright from "./config/copyright";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      scrollTop: 0,
      navIndex: 0,
      copyrightText: "苏ICP备14037250号",
      navs: [
        {
          name: "首页",
          to: "/",
        },
        {
          name: "关于小熊",
          to: "/",
        },
        {
          name: "商务合作",
          to: "/",
        },
        {
          name: "加入我们",
          to: "/",
        },
        {
          name: "联系我们",
          to: "/",
        },
      ],
    };
  },
  watch: {
    $route(to) {
      this.changeNavIndex(to.name);
    },
  },
  mounted() {
    let href = window.location.href;
    copyright.forEach((item)=>{
      if(href.indexOf(item.url) !== -1){
        this.copyrightText = item.text
      }
    })
    window.addEventListener("scroll", () => {
      this.scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
    });
  },
  methods: {
    // 导航下标
    pageTo(index) {
      this.navIndex = index;
      switch (index) {
        case 0:
          document.documentElement.scrollTop = document.body.scrollTop = 0;
          this.$router.push({ name: "Home" });
          break;
        case 1:
          this.$router.push({ name: "Home" });
          this.$nextTick(() => {
            setTimeout(() => {
              this.navIndex = index;
              document.documentElement.scrollTop = document.body.scrollTop = 690;
            }, 300);
          });
          break;
        case 2:
          this.$router.push({ name: "Cooperation" });
          break;
        case 3:
          this.$router.push({ name: "JoinUs" });
          break;
        case 4:
          this.$router.push({ name: "ContactUs" });
          break;
        default:
          this.navIndex = 5;
          this.$router.push({ name: "Download" });
          break;
      }
    },
    changeNavIndex(name) {
      switch (name) {
        case "Cooperation":
          this.navIndex = 2;
          break;
        case "JoinUs":
          this.navIndex = 3;
          break;
        case "ContactUs":
          this.navIndex = 4;
          break;
        case "Home":
          this.navIndex = 0;
          break;
        default:
          this.navIndex = 5;
          break;
      }
    },
    VscrollTop() {
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
  },
};
</script>
