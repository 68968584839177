<style lang="less">
@import url("../assets/less/base.less");
.home {
  .first-page {
    background: url("../assets/img/首页_slices/img_home_top.png") no-repeat
      #fed501;
    background-position: calc(50% + 230px) center;
    .app-content {
      position: relative;
      height: 768px;
      display: flex;
      align-content: center;
      justify-content: space-between;
      .-left {
        .content {
          font-size: 56px;
          color: #333333;
          line-height: 77px;
          white-space: nowrap;
          padding-top: 224px;
          font-family: "future";
        }
        .btns {
          display: flex;
          align-items: center;
          font-size: 0;
          margin-top: 44px;
          a {
            display: block;
            width: 160px;
            height: 60px;
            background-image: url("../assets/img/首页_slices/btn_download_ios.png");
            background-size: 100% 100%;
            & + a {
              background-image: url("../assets/img/首页_slices/btn_download_android.png");
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
  .model-1 {
    height: 460px;
    background: #fff;
    .app-content {
      position: relative;
      .swiper-pagination {
        position: absolute;
        bottom: 42px;
        z-index: 1;
        left: 50%;
        .swiper-pagination-bullet {
          width: 40px;
          height: 2px;
          background: #eeeeee;
          border-radius: 2px;
          opacity: 1;
          margin: 0 5px;
          &.swiper-pagination-bullet-active {
            background: #ffca00;
          }
        }
      }
    }
  }
  .model-2 {
    background: linear-gradient(to bottom, #f7f7f7 0%, #ffffff 100%);
    .app-content {
      position: relative;
      padding-bottom: 50px;
      &::before {
        position: absolute;
        content: "";
        width: 237px;
        height: 237px;
        background: linear-gradient(
          360deg,
          rgba(255, 202, 0, 0) 0%,
          #ffca00 100%
        );
        opacity: 0.19;
        border-radius: 50%;
        bottom: 36px;
        left: 116px;
        z-index: 1;
      }
      h1 {
        text-align: center;
        font-size: 36px;
        color: #333333;
        line-height: 50px;
        padding: 57px 0 37px;
        font-family: "future";
      }
      .why-list {
        position: relative;
        z-index: 2;
        overflow: auto;
        .why-item {
          float: left;
          width: 384px;
          height: 200px;
          background-color: #ffffff;
          box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);
          border-radius: 20px;
          background-repeat: no-repeat;
          background-position: left;
          margin-bottom: 24px;
          padding: 40px 0 0 180px;
          box-sizing: border-box;
          &:nth-child(1) {
            background-image: url("../assets/img/首页_slices/img_why_01.png");
            margin-right: 24px;
          }
          &:nth-child(2) {
            background-image: url("../assets/img/首页_slices/img_why_02.png");
            margin-right: 24px;
          }
          &:nth-child(3) {
            background-image: url("../assets/img/首页_slices/img_why_03.png");
          }
          &:nth-child(4) {
            background-image: url("../assets/img/首页_slices/img_why_04.png");
            margin-left: 204px;
            margin-right: 24px;
          }
          &:nth-child(5) {
            background-image: url("../assets/img/首页_slices/img_why_05.png");
          }
          h3 {
            font-size: 20px;
            color: #333333;
            line-height: 28px;
          }
          .tips {
            font-size: 16px;
            color: #666666;
            line-height: 22px;
            margin-top: 19px;
          }
        }
      }
      .btn {
        width: 224px;
        height: 60px;
        border-radius: 16px;
        border: 1px solid #333333;
        font-size: 20px;
        color: #302600;
        line-height: 60px;
        text-align: center;
        margin: 0 auto;
        margin-top: 25px;
      }
    }
  }
  .model-3 {
    background: #fff;
    .app-content {
      height: 330px;
      background: url("../assets/img/首页_slices/img_home_data.png");
    }
  }
  .model-4 {
    h1 {
      text-align: center;
      font-size: 36px;
      color: #333333;
      line-height: 50px;
      padding: 57px 0 37px;
      font-family: "future";
    }
    .app-content {
      height: 233px;
      background: url("../assets/img/首页_slices/img_home_experience.png");
      margin: 40px auto 80px;
    }
  }
  .model-5 {
    background: #fffdf9;
    padding: 58px 0 84px;
    .app-content {
      height: 238px;
      background: url("../assets/img/首页_slices/img_home_pingtai.png");
    }
  }
}
</style>
<template>
  <div class="home">
    <!-- 首屏 -->
    <div class="first-page">
      <div class="app-content">
        <div class="-left">
          <div class="content">
            致力于<br />服务全国用户的<br />社交APP导购平台
          </div>
          <div class="btns">
            <a href="https://apps.apple.com/cn/app/id1297227820" target="_blank"
              >ios下载</a
            >
            <a
              href="https://sj.qq.com/myapp/detail.htm?apkName=com.xiaoxiongyhh&info=E1A0FBB267301F7AFE6376C2937074B8"
              target="_blank"
              >Android下载</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 轮播 -->
    <div class="model-1">
      <div class="app-content">
        <Swiper pagination autoplay :loop="true">
          <SwiperSlide
            class="swiper-slide"
            v-for="(item, index) in swiperList"
            :key="index"
          >
            <img :src="item" alt="" />
          </SwiperSlide>
          <div class="swiper-pagination"></div>
        </Swiper>
      </div>
    </div>
    <!-- 为什么选择小熊 -->
    <div class="model-2">
      <div class="app-content">
        <h1>为什么选择小熊有好货</h1>
        <div class="why-list">
          <div class="why-item" v-for="(item, index) in whyList" :key="index">
            <h3>{{ item.name }}</h3>
            <div class="tips">
              <p v-for="(p, index1) in item.tips" :key="index1">{{ p }}</p>
            </div>
          </div>
        </div>
        <div class="btn" @click="pageTo">了解小熊有好货</div>
      </div>
    </div>
    <!-- 服务数据 -->
    <div class="model-3">
      <div class="app-content"></div>
    </div>
    <!-- 步履不停，服务不止 -->
    <div class="model-4">
      <h1>步履不停，服务不止</h1>
      <div class="app-content"></div>
    </div>
    <!-- 步履不停，服务不止 -->
    <div class="model-5">
      <div class="app-content"></div>
    </div>
  </div>
</template>

<script>
import SwiperCore, { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
SwiperCore.use([Autoplay, Pagination]);
import "swiper/swiper.less";

export default {
  name: "Home",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperList: [
        require("../assets/img/首页_slices/img_home_banner_01.png"),
        require("../assets/img/首页_slices/img_home_banner_02.png"),
        require("../assets/img/首页_slices/img_home_banner_03.png"),
      ],
      whyList: [
        { name: "省钱赚钱", tips: ["自用省钱", "分享赚钱"] },
        {
          name: "优惠券齐全",
          tips: ["汇聚各大平台", "99%隐藏优惠券，", "为您省钱"],
        },
        {
          name: "高返利 秒提现",
          tips: ["平台返利高，", "欢迎比价"],
        },
        {
          name: "福利多",
          tips: ["签到赚现金", "天天抢免单"],
        },
        {
          name: "安全保障",
          tips: [
            "购物售前售后安全保障，",
            "app 内领券自动跳转",
            "平台完成购物",
          ],
        },
      ],
    };
  },
  mounted() {},
  methods: {
    pageTo() {
      this.$router.push({ name: "Download" });
    },
  },
};
</script>
